(function () {
  const Swiper = window.Swiper;
  const CarouselContainer = document.querySelectorAll(
    '.testimonials__container'
  );

  if (0 < CarouselContainer.length) {
    new Swiper('.testimonials__container', {
      direction: 'horizontal',
      loop: true,
      autoplay: {
        delay: 7500,
        disableOnInteraction: true,
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      keyboard: true,

      pagination: {
        el: '.testimonials__navigation',
        clickable: 'true',
      },
      navigation: {
        nextEl: '.testimonials__navigation--next',
        prevEl: '.testimonials__navigation--prev',
      },
    });
  }
})(jQuery); // Fully reference jQuery after this point.
